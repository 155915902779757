import React from 'react';
import NotFound from '../templates/404page/NotFound';

export default function NotFoundPage() {
  return (
    <div>
      <NotFound />
    </div>
  );
}
