import React from 'react';
import Header from '../../components/header/header';
import Seo from '../../layouts/common/seo';
import ErrorImage from '../../assets/404/svg-icons/error-mage';
import BlogButton from '../../components/reusable-buttons/blog-button';
import { Link } from 'gatsby';
export default function NotFound() {
  return (
    <div>
      <Header>
        <Seo />
      </Header>
      <div className="error-wrapper common-container u-margin-top-lg">
        <ErrorImage />
        <div className="error-texts ">
          <h1>Oops!</h1>
          <h3>We cant seem to find the page you are looking for. </h3>
          <p>Error code:404</p>
        </div>
        <Link to="/">
          <div className="error-btn u-margin-top-sm">
            <BlogButton buttonText="Go To Homepage" />
          </div>
        </Link>
      </div>
    </div>
  );
}
